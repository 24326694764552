import "./ScrollToTop.scss";
import { IoIosArrowUp } from "@react-icons/all-files/io/IoIosArrowUp";
import React from "react";

const ScrollToTop = (props) => {
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div
      className={props.showButton ? "scrollBtn" : "hide-btn scrollBtn"}
      onClick={goToTop}
    >
      <div className="top">
        <IoIosArrowUp size={23} color={"var(--textcolor)"} />
      </div>
    </div>
  );
};

export default React.memo(ScrollToTop);
